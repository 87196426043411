@import url(https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Dancing+Script&family=DotGothic16&family=Indie+Flower&family=Poppins:wght@100;400;700&family=Roboto:wght@100;400;700&family=Sacramento&family=Shadows+Into+Light&family=Teko&display=swap);
body {
  font-family: "Poppins", sans-serif !important;
}

.header {
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0px !important;
}

.logo {
  float: left;
  width: 220px;
  background: rgba(255, 255, 255, 0.3);
  margin-left: 10px;
}

.logo img {
  width: 220px;
}

.ant-row-rtl #components-layout-demo-top-side .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card.white {
  background: #fbfbfb;
}

.worksheet-pages {
  width: 511pt;
  margin-left: 10px;
  min-height: 700pt;
  text-align: center;
}

.worksheet-pages.display-optimized {
  width: 100%;
  height: 100%;
}

.worksheet-container {
  overflow: scroll;
}

.side-menu-button {
  display: none;
}
@media only screen and (max-width: 768px) {
  .top-menu {
    display: none;
  }

  .side-menu-button {
    display: inherit !important;
    margin-left: auto;
    margin-right: 6px;
    -webkit-align-items: center;
            align-items: center;
  }
}

.home {
  display: -webkit-flex;
  display: flex;
  max-width: 800px;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: white;
  padding: 30px;
  margin: auto;
  /* font-family: 'Dancing Script', monospace; */
  font-size: 18px;
}

.home h1 {
  -webkit-align-self: center;
          align-self: center;
}

.regenerate .ant-card-body {
  padding: 10px;
}

.hint {
  font-size: 10px;
  padding-top: 5px;
}

@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0px;
  }
  .settings {
    display: none;
  }
  .worksheet-pages {
    padding: 0px;
  }
  .header {
    display: none;
  }
}

#section-to-print {
  padding-top: 30px;
}
.display-settings-row {
  margin-top: 10px;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 10px;
}

.display-settings-row .label {
  font-weight: 700;
}

.alignment-type-selector  {
  background-color: blueviolet;
}

.addition-problem {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  border-bottom: 2px solid;
  padding: 0px 10px 5px 10px;
}

.addition-problem-horizontal {
  -webkit-flex-direction: column;
          flex-direction: column;
  border-bottom: 0px solid;
}

.addition-problem-horizontal .addition-row::after {
  content: " + ";
}

.addition-problem-horizontal .addition-row:nth-last-child(3)::after {
  content: "";
}

.addition-problem .equal {
  display: none;
}

.addition-problem .answer-line {
  display: none;
}

.answer-line {
  border: 1px solid;
  display: inline-block;
  width: 60px;
  height: 35px;
}



.addition-problem .addition-row:nth-last-child(3)::before {
  content: " + ";
}

.subtraction-problem {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  border-bottom: 2px solid;
  padding: 0px 10px 5px 10px;
}

.subtraction-problem-horizontal {
  -webkit-flex-direction: column;
          flex-direction: column;
  border-bottom: 0px solid;
}

.subtraction-problem-horizontal .subtraction-row::after {
  content: " - ";
}

.subtraction-problem-horizontal .subtraction-row:nth-last-child(3)::after {
  content: "";
}

.subtraction-problem .equal {
  display: none;
}

.subtraction-problem .answer-line {
  display: none;
}

.subtraction-problem .subtraction-row:nth-last-child(3)::before {
  content: " - ";
}

.multiplication-problem {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  border-bottom: 2px solid;
  padding: 0px 10px 5px 10px;
}

.multiplication-problem :last-child::before {
  content: " X ";
}

.settings .ant-card {
  margin: 5px;
}

.settings .regenerate {
  text-align: center;
}

